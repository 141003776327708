















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  emits: ["open"],
  props: {
    name: {
      type: String,
      default: "",
    },
  },
});

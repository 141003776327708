const checkPlatform = () => {
  let userAgent = window.navigator.userAgent;
  userAgent = userAgent.toLowerCase();
  const platform: { [key: string]: boolean } = {};
  const matched: { [key: string]: string } = {};
  let userPlatform = "";
  const platform_match =
    /(ipad)/.exec(userAgent) ||
    /(ipod)/.exec(userAgent) ||
    /(windows phone)/.exec(userAgent) ||
    /(iphone)/.exec(userAgent) ||
    /(kindle)/.exec(userAgent) ||
    /(silk)/.exec(userAgent) ||
    /(android)/.exec(userAgent) ||
    /(win)/.exec(userAgent) ||
    /(mac)/.exec(userAgent) ||
    /(linux)/.exec(userAgent) ||
    /(cros)/.exec(userAgent) ||
    /(playbook)/.exec(userAgent) ||
    /(bb)/.exec(userAgent) ||
    /(blackberry)/.exec(userAgent) ||
    [];

  matched["platform"] = platform_match[0] ? platform_match[0] : "";
  if (matched["platform"]) {
    platform[matched["platform"]] = true;
  }

  if (
    platform["android"] ||
    platform["bb"] ||
    platform["blackberry"] ||
    platform["ipad"] ||
    platform["iphone"] ||
    platform["ipod"] ||
    platform["kindle"] ||
    platform["playbook"] ||
    platform["silk"] ||
    platform["windows phone"]
  ) {
    userPlatform = "mobile";
  }

  if (
    platform["cros"] ||
    platform["mac"] ||
    platform["linux"] ||
    platform["win"]
  ) {
    userPlatform = "pc";
  }

  return userPlatform;
};

export default checkPlatform;


















// TODO: 확장성 및 기본적인 checkbox의 동작을 고려한 컴포넌트 설계 필요
interface ChangeColor {
  prev: string;
  after: string;
}
import CheckIcon from "@/assets/ElementsImage/CheckIcon.vue";
import { defineComponent, computed } from "@vue/composition-api";
export default defineComponent({
  props: {
    check: { type: Boolean, required: true },
    size: { type: Number, default: 24 },
    background: {
      type: Object as () => ChangeColor,
      defalut: { prev: "#D9D9D9", after: "#0078f0" },
    },
    checkColor: {
      type: Object as () => ChangeColor,
    },
    disabled: {
      type: Boolean,
    },
  },
  components: {
    CheckIcon,
  },
  emits: ["check"],
  setup(props, context) {
    const checked = () => {
      context.emit("check");
    };
    const backgroundColor = computed(() => {
      if (!props.background) {
        if (props.check) {
          return "#0078f0";
        } else {
          return "#D9D9D9";
        }
      }

      if (props.check) {
        return props.background.after;
      } else {
        return props.background.prev;
      }
    });

    const calcCheckColor = computed(() => {
      if (!props.checkColor) return "white";
      if (props.check) {
        return props.checkColor.after;
      } else {
        return props.checkColor.prev;
      }
    });
    return { checked, backgroundColor, calcCheckColor };
  },
});


























































































































































































































































































































































































































































































































































































































































































































































































import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  watch,
  nextTick,
  onBeforeUnmount,
  watchEffect,
} from "@vue/composition-api";
import axios from "axios";
import camelCaseKeys from "camelcase-keys";
import snakeCaseKeys from "snakecase-keys";

import { embedAddressForm } from "@/components/Address/DaumPostcode";
import checkPlatform from "@/components/Hooks/checkPlatform";
import goPay from "@/components/Nicepay/NicepaySDK";
import { getNaverpaySDK } from "@/components/Naverpay/NaverpaySDK";
import formatNumber from "@/components/Hooks/formatNumber";
import checkEmail from "@/components/Hooks/checkEmail";
import toOrderPeviews from "@/components/Order/Hooks/toOrderPreviews";
import getLocalStorageCart from "@/components/Hooks/getLocalStorageCart";
import requestBankInfo from "@/components/Order/Hooks/requestBankInfo";
import { useStore } from "@/libs/store";
import { useRouter } from "@/libs/router";
import { useNicepaySignData } from "@/components/Nicepay/useNicepaySignData";
import { useForm } from "@/components/Order/Hooks/useForm";
import { SLACK_WEBHOOK_URL, sendSlackWebHook } from "@/utils/slack";

import type { SelectedProduct } from "@/components/Product/Types";
import type { Shop as IShop } from "@/components/Shop/Types";
import type { State as RootState } from "@/store";

import BaseLayout from "@/components/Layout/BaseLayout.vue";
import MyshopInfoLayout from "@/components/Layout/MyshopInfoLayout.vue";
import SmartPhoneLayout from "@/components/Layout/SmartPhoneLayout.vue";
import Product from "@/components/Order/Product.vue";
import NicepayForm from "@/components/Nicepay/NicepayForm.vue";
import BaseHeader from "@/components/Layout/BaseHeader.vue";
import MainTextInput from "@/components/Common/MainTextInput.vue";
import MainTextInputIcon from "@/components/Common/MainTextInputIcon.vue";
import SearchIcon from "@/assets/ElementsImage/SearchIcon.vue";
import ArrowDownIcon from "@/assets/ElementsImage/Arrow-Down.vue";
import OrderErrorMessage from "@/components/Order/OrderErrorMessage.vue";
import UpdownArrow from "@/components/Common/UpDownArrow.vue";
import CardIcon from "@/assets/ElementsImage/CardIcon2.vue";
import BankBookIcon from "@/assets/ElementsImage/BankBookIcon.vue";
import PhoneIcon from "@/assets/ElementsImage/PhoneIcon.vue";
import BottomFixedModal from "@/components/Common/BottomFixedModal.vue";
import CloseIcon from "@/assets/ElementsImage/CloseIcon2.vue";
import OrderPreview from "@/components/Common/OrderPreview.vue";
import CheckBox from "@/components/Common/CheckBox.vue";
import { Checkbox as CheckboxV2 } from "@/components/Common/v2/Checkbox";
import ChannelTalk from "@/components/ChannelTalk/ChannelTalk.vue";
import LoadingSpinnerBox from "@/components/Common/LoadingSpinnerBox.vue";
import OrderTermsContainer from "@/components/Order/OrderTermsContainer.vue";
import OrderAgreementList from "@/components/Order/OrderAgreementList.vue";
import OrderAgreementItem from "@/components/Order/OrderAgreementItem.vue";
import PersonalCustomCodeAgreement from "@/components/Order/PersonalCustomCodeAgreement.vue";
import Modal from "@/components/Common/Modal.vue";

interface ShopperInfo {
  name: string;
  email: string;
  phone: string;
  phoneCheck: string;
  isMarketingAgree: boolean;
}

interface ShippingInfo {
  recipientName: string;
  zipcode: string;
  address: string;
  addressDetail: string;
  message: string;
  phone: string;
  phoneCheck: string;
}

export default defineComponent({
  components: {
    BaseLayout,
    MyshopInfoLayout,
    SmartPhoneLayout,
    Product,
    NicepayForm,
    BaseHeader,
    MainTextInput,
    MainTextInputIcon,
    SearchIcon,
    ArrowDownIcon,
    OrderErrorMessage,
    UpdownArrow,
    CardIcon,
    BankBookIcon,
    PhoneIcon,
    BottomFixedModal,
    CloseIcon,
    OrderPreview,
    CheckBox,
    CheckboxV2,
    ChannelTalk,
    LoadingSpinnerBox,
    OrderTermsContainer,
    OrderAgreementList,
    OrderAgreementItem,
    PersonalCustomCodeAgreement,
    Modal,
  },
  props: {
    shopInfo: {
      type: Object as () => IShop,
      required: true,
    },
  },
  setup(props) {
    const store = useStore<RootState>();
    const router = useRouter();

    const productList = computed<SelectedProduct[]>(() => {
      const orderProducts = store.state.orderProducts[props.shopInfo.idx];

      if (orderProducts && orderProducts.length > 0) {
        return orderProducts;
      } else {
        alert("상품을 먼저 선택해주세요");
        router.go(-1);
        return [];
      }
    });

    const hasRecruitmentProduct = computed(() =>
      productList.value.some((product) => product.sellType === "RECRUITMENT")
    );

    const selectedCartIndices = computed(() => {
      return store.state.selectedCartIndices[props.shopInfo.idx] || [];
    });

    const orderPreviews = computed(() => {
      if (productList.value.length === 0) return [];

      const orderPreviews = toOrderPeviews(productList.value);

      return orderPreviews;
    });

    const hasAbroadProduct = computed(() => {
      return productList.value.some((product) => product.isAbroad);
    });

    const filterOnlyNumber = (value: string) => {
      return value.replace(/[^0-9]/g, "");
    };

    const {
      values: shopperInfo,
      errors: shopperErrors,
      input: inputShopperInfo,
      validate: validateShopperInfo,
      validateAll: validateAllShopperInfo,
    } = useForm({
      initialValues: {
        name: "",
        email: "",
        phone: "",
        phoneCheck: "",
        isMarketingAgree: true,
      },
      validators: {
        name: (name) => {
          return name.length === 0 ? "구매자 이름을 입력하세요" : null;
        },
        email: (email) => {
          if (email.length === 0) return "이메일을 입력하세요";
          if (!checkEmail(email)) return "이메일을 바르게 입력하세요";
          return null;
        },
        phone: (phone) => {
          return phone.length === 0 ? "구매자 연락처를 입력하세요" : null;
        },
        phoneCheck: (phoneCheck) => {
          if (shopperInfo.phone.length === 0) {
            return null;
          }
          if (phoneCheck.length === 0) {
            return "연락처를 한번 더 입력하세요";
          }
          if (phoneCheck !== shopperInfo.phone) {
            return "연락처를 다시 확인해 주세요";
          }
          return null;
        },
      },
    });

    const inputShopperHandler = (event: Event) => {
      const { name, value, checked } = event.target as HTMLInputElement;
      switch (name) {
        case "name":
        case "email":
          return inputShopperInfo(name, value);

        case "phone":
        case "phoneCheck":
          return inputShopperInfo(name, filterOnlyNumber(value));

        case "isMarketingAgree":
          return inputShopperInfo(name, !checked);
      }
    };

    const personalCustomsCode = ref("");
    const isPersonalCustomsCodeError = ref(false);

    const inputPersonalCustomsCodeHandler = (value: string) => {
      personalCustomsCode.value = value;
      isPersonalCustomsCodeError.value = false;
    };

    const checkValidPersonalCustomsCode = (code: string) => {
      const pattern = /^P[0-9]{12}$/;

      return pattern.test(code);
    };

    const blurPersonalCustomsCodeHandler = () => {
      personalCustomsCode.value = personalCustomsCode.value.toUpperCase();

      if (!checkValidPersonalCustomsCode(personalCustomsCode.value)) {
        isPersonalCustomsCodeError.value = true;
      }
    };

    const {
      values: shippingInfo,
      errors: shippingErrors,
      input: inputShippingInfo,
      validate: validateShippingInfo,
      validateAll: validateAllShippingInfo,
    } = useForm({
      initialValues: {
        recipientName: "",
        zipcode: "",
        address: "",
        addressDetail: "",
        phone: "",
        phoneCheck: "",
        message: "",
      },
      validators: {
        recipientName: (name) => {
          return name.length === 0 ? "수령인을 입력하세요" : null;
        },
        address: (address) => {
          if (address.length === 0 || shippingInfo.addressDetail.length === 0) {
            return "주소를 입력하세요";
          }
          return null;
        },
        phone: (phone) => {
          return phone.length === 0 ? "연락처를 입력하세요" : null;
        },
        phoneCheck: (phoneCheck) => {
          if (shippingInfo.phone.length === 0) {
            return null;
          }
          if (phoneCheck.length === 0) {
            return "연락처를 한번 더 입력하세요";
          }
          if (phoneCheck !== shippingInfo.phone) {
            return "연락처를 다시 확인해 주세요";
          }
          return null;
        },
      },
    });

    const inputShippingHandler = (event: Event) => {
      const { name, value } = event.target as HTMLInputElement;
      switch (name) {
        case "recipientName":
        case "message":
          return inputShippingInfo(name, value);

        case "addressDetail":
          inputShippingInfo(name, value);
          return validateShippingInfo("address");

        case "phone":
        case "phoneCheck":
          return inputShippingInfo(name, filterOnlyNumber(value));
      }
    };

    watch(
      () => shippingInfo.address,
      () => validateShippingInfo("address")
    );

    const isShippingInfoEqual = ref(false);
    watch(
      () => isShippingInfoEqual.value,
      (newValue) => {
        if (newValue) {
          const { name, phone } = shopperInfo;
          inputShippingInfo("recipientName", name);
          inputShippingInfo("phone", phone);
        }
      }
    );
    watch(
      () => ({
        recipientName: shippingInfo.recipientName,
        phone: shippingInfo.phone,
      }),
      (newValue) => {
        if (!isShippingInfoEqual.value) {
          return;
        }
        const { recipientName, phone } = newValue;

        const { name, phone: shopperPhone } = shopperInfo;

        if (recipientName !== name || phone !== shopperPhone) {
          isShippingInfoEqual.value = false;
        }
      }
    );
    watch(
      () => ({
        name: shopperInfo.name,
        phone: shopperInfo.phone,
      }),
      (newValue) => {
        if (!isShippingInfoEqual.value) {
          return;
        }

        const { name, phone: shopperPhone } = newValue;

        inputShippingInfo("recipientName", name);
        inputShippingInfo("phone", shopperPhone);
      }
    );

    const isDeliveryRequestModalOpened = ref(false);
    const deliveryRequestInputText = ref("");
    const isDeliveryRequestMemoVisible = ref(false);

    const deliveryRequestList = [
      "부재 시 경비실에 맡겨주세요",
      "부재 시 택배함에 넣어주세요",
      "부재 시 집앞에 놔주세요",
      "배송 전 연락 바랍니다",
    ];

    const clickDefaultDeliveryRequest = (value: string) => {
      shippingInfo.message = value;
      deliveryRequestInputText.value = value;
      isDeliveryRequestModalOpened.value = false;
      isDeliveryRequestMemoVisible.value = false;
    };

    const clickDeliveryRequestMemoHandler = () => {
      shippingInfo.message = "";
      deliveryRequestInputText.value = "직접 입력";
      isDeliveryRequestModalOpened.value = false;
      isDeliveryRequestMemoVisible.value = true;
    };

    const addressSearchContainerEl = ref();
    const isAddressSearchContainerVisible = ref(false);

    const fillAddressForm = (addressObj: { [key: string]: string }) => {
      shippingInfo.zipcode = addressObj.zonecode;
      shippingInfo.address = addressObj.address;
      validateShippingInfo("address");
      isAddressSearchContainerVisible.value = false;
    };

    const openAddressSearchHandler = () => {
      isAddressSearchContainerVisible.value = true;
      nextTick(() =>
        embedAddressForm(addressSearchContainerEl.value, fillAddressForm)
      );
    };

    const deliveryFee = ref(0);
    const amount = ref(0);
    const point = ref(0);
    const totalLocalProductsPrice = computed(() => {
      const total = productList.value.reduce((total: any, product: any) => {
        const productPrice = product.price;
        const totalOptionAddPrice = product.options.reduce(
          (total: any, option: any) => {
            return total + option.addPrice;
          },
          0
        );

        return total + (productPrice + totalOptionAddPrice) * product.qty;
      }, 0);

      return total;
    });

    watch(
      () => amount.value,
      (newValue) => {
        if (newValue !== totalLocalProductsPrice.value) {
          alert("상품 정보가 변경되었습니다. 상품을 다시 선택해주세요.");
          router.push({ name: "Myshop" });
        }
      }
    );

    const toProductListForm = (productList: SelectedProduct[]) => {
      const productListForm = productList.map((product) => {
        const baseOptions = product.options;
        // const additionalOptions = [];
        let baseOptionObj: { optionIdx: number }[] = [];
        if (baseOptions.length > 0) {
          baseOptionObj =
            product.optionType === "SINGLE"
              ? product.options.map((option) => ({
                  optionIdx: option.optionIdx,
                }))
              : product.options
                  .map((option) => ({ optionIdx: option.optionIdx }))
                  .slice(-1);
        }

        // TODO: 추가 옵션 선택 가능할 때 추가 개발 필요
        const additionalOptionsForm: {
          optionIdx: number;
          text: string | null;
        }[] = [];
        return {
          productNo: product.productNo,
          qty: product.qty,
          option: {
            optionIdx: baseOptionObj,
            additionalOptions: additionalOptionsForm,
          },
        };
      });

      return productListForm;
    };

    const createCalcOrderPriceRequestBody = (
      productList: SelectedProduct[],
      shippingInfo: ShippingInfo
    ) => {
      const productListForm = toProductListForm(productList);
      const body = {
        productList: productListForm,
        shippingInfo: {
          address: shippingInfo.address,
          addressDetail: shippingInfo.addressDetail,
          message: shippingInfo.message,
          phone: shippingInfo.phone,
          recipientName: shippingInfo.recipientName,
          zipcode: shippingInfo.zipcode,
        },
      };
      return snakeCaseKeys(body, { deep: true });
    };

    const requestCalcOrderPrice = async (
      productList: SelectedProduct[],
      shippingInfo: ShippingInfo
    ) => {
      const body = createCalcOrderPriceRequestBody(productList, shippingInfo);

      const res = await axios.post(
        `${process.env.VUE_APP_BACKEND_SERVER}/order/calculate/`,
        body
      );

      const data = camelCaseKeys(res.data.data, { deep: true });

      return data;
    };

    onMounted(async () => {
      const requestBody = createCalcOrderPriceRequestBody(
        productList.value,
        shippingInfo
      );

      try {
        const data = await requestCalcOrderPrice(
          productList.value,
          shippingInfo
        );
        amount.value = data.amount;
        deliveryFee.value = data.deliveryFee;
      } catch (err) {
        alert("상품 정보가 변경되었습니다. 상품을 다시 선택해주세요.");
        router.push({ name: "Myshop" });
        sendSlackWebHook(SLACK_WEBHOOK_URL.LOG_DEBUG, {
          blocks: [
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `[tagby-commerce-2.0] /order/calculate/ Error log
origin: ${location.origin}
request body: ${JSON.stringify(requestBody)}

error detail:
${JSON.stringify(err)}`,
              },
            },
          ],
        });
      }
    });

    watch(
      () => shippingInfo.address,
      async () => {
        if (!productList.value) return;

        const requestBody = createCalcOrderPriceRequestBody(
          productList.value,
          shippingInfo
        );

        try {
          const data = await requestCalcOrderPrice(
            productList.value,
            shippingInfo
          );
          amount.value = data.amount;
          deliveryFee.value = data.deliveryFee;
        } catch (err) {
          alert("상품 정보가 변경되었습니다. 상품을 다시 선택해주세요.");
          router.push({ name: "Myshop" });
          sendSlackWebHook(SLACK_WEBHOOK_URL.LOG_DEBUG, {
            blocks: [
              {
                type: "section",
                text: {
                  type: "mrkdwn",
                  text: `[tagby-commerce-2.0] /order/calculate/ Error log
origin: ${location.origin}
request body: ${JSON.stringify(requestBody)}

error detail:
${JSON.stringify(err)}`,
                },
              },
            ],
          });
        }
      }
    );

    const {
      ediDate,
      isValid: isSignDataValid,
      mutate: mutateSignData,
      signData,
    } = useNicepaySignData(computed(() => amount.value + deliveryFee.value));

    const isProductListOpened = ref(false);
    const productListButtonText = computed(() => {
      return isProductListOpened.value ? "닫기" : "펼치기";
    });

    const paymentMethod = ref<"CARD" | "CELLPHONE" | "NAVER_PAY" | "BANK">();
    const bankInfo = ref();
    const depositorName = ref("");
    const depositorNameInputEl = ref();

    watch(
      () => paymentMethod.value,
      async () => {
        if (paymentMethod.value === "BANK" && !bankInfo.value) {
          try {
            const res = await requestBankInfo();
            bankInfo.value = res;
          } catch {
            alert("은행 정보를 불러오지 못했습니다.");
          }
        }
      }
    );

    const cashReceiptsList = [
      { key: "NONE", label: "미신청" },
      { key: "PERSONAL", label: "개인소득공제용" },
      { key: "BUSINESS", label: "사업자지출증빙용" },
    ];

    const personalCashReceiptsList = [
      { key: "PHONE_NUMBER", label: "휴대폰 번호" },
      { key: "RESIDENT_NUMBER", label: "주민등록번호" },
      { key: "CARD_NUMBER", label: "현금영수증 카드번호" },
    ];

    const isCashReceiptsModalOpened = ref(false);
    const selectedCashReceipts = ref<{ key: string; label: string }>();
    const selectCashReceipstHandler = (cashReceipts: {
      key: string;
      label: string;
    }) => {
      selectedCashReceipts.value = cashReceipts;
      isCashReceiptsModalOpened.value = false;
    };

    const isPersonalCashReceiptsModalOpened = ref(false);
    const selectedPersonalCashReceipts = ref<{ key: string; label: string }>({
      ...personalCashReceiptsList[0],
    });
    const selectPersonalCashReceiptsHandler = (cashReceipts: {
      key: string;
      label: string;
    }) => {
      selectedPersonalCashReceipts.value = cashReceipts;
      isPersonalCashReceiptsModalOpened.value = false;
    };

    const selectedCashReceiptsKey = computed(() => {
      if (
        !selectedCashReceipts.value ||
        selectedCashReceipts.value.key === "NONE"
      )
        return "none";
      if (selectedCashReceipts.value.key === "BUSINESS") return "business";
      if (selectedPersonalCashReceipts.value.key === "PHONE_NUMBER")
        return "phone";
      if (selectedPersonalCashReceipts.value.key === "RESIDENT_NUMBER")
        return "resident";
      if (selectedPersonalCashReceipts.value.key === "CARD_NUMBER")
        return "card";
      return "none";
    });

    const cashReceiptsNumberInputData = reactive({
      none: [{ type: "tel", value: "" }],
      phone: [{ type: "tel", value: "" }],
      resident: [
        { type: "tel", value: "", maxLen: 6 },
        { type: "password", value: "", maxLen: 7 },
      ],
      card: [
        { type: "tel", value: "", maxLen: 4 },
        { type: "tel", value: "", maxLen: 4 },
        { type: "tel", value: "", maxLen: 4 },
        { type: "tel", value: "", maxLen: 7 },
      ],
      business: [
        { type: "tel", value: "", maxLen: 3 },
        { type: "tel", value: "", maxLen: 2 },
        { type: "password", value: "", maxLen: 5 },
      ],
    });

    const cashReceiptsNumber = computed(() => {
      if (selectedCashReceiptsKey.value === "none") {
        return "";
      }

      const selectedCashReceiptsNumberInputData = cashReceiptsNumberInputData[
        selectedCashReceiptsKey.value
      ] as { type: string; value: string; maxLen?: number }[];
      const formattedCashReceiptsNumber =
        selectedCashReceiptsNumberInputData.reduce(
          (
            acc: string,
            cur: { type: string; value: string; maxLen?: number }
          ) => acc + cur.value,
          ""
        );

      return formattedCashReceiptsNumber;
    });

    const checkCashReceiptsNumber = (
      cashReceiptsKey: "phone" | "resident" | "card" | "business",
      cashReceiptsNumber: string
    ) => {
      if (
        (cashReceiptsKey === "phone" && cashReceiptsNumber.length <= 0) ||
        (cashReceiptsKey === "resident" && cashReceiptsNumber.length !== 13) ||
        (cashReceiptsKey === "card" &&
          (cashReceiptsNumber.length < 13 || cashReceiptsNumber.length > 19)) ||
        (cashReceiptsKey === "business" && cashReceiptsNumber.length !== 10)
      ) {
        return false;
      }
      return true;
    };

    // TODO: 회원 현금 영수증 저장 여부 추가
    const toCashReceiptsForm = (
      cashReceiptsKey: "none" | "phone" | "resident" | "card" | "business",
      cashReceiptsNumber: string
    ):
      | {
          cashReceiptType: "PERSONAL" | "COMPANY";
          cashReceiptIdType: "PHONE" | "SSN" | "COMPANY_NO" | "CARD_NO";
          cashReceiptId: string;
          isCashReceiptSave: boolean;
        }
      | undefined => {
      if (
        cashReceiptsKey === "none" ||
        !checkCashReceiptsNumber(cashReceiptsKey, cashReceiptsNumber)
      ) {
        return undefined;
      } else if (cashReceiptsKey === "phone") {
        return {
          cashReceiptType: "PERSONAL",
          cashReceiptIdType: "PHONE",
          cashReceiptId: cashReceiptsNumber,
          isCashReceiptSave: false,
        };
      } else if (cashReceiptsKey === "resident") {
        return {
          cashReceiptType: "PERSONAL",
          cashReceiptIdType: "SSN",
          cashReceiptId: cashReceiptsNumber,
          isCashReceiptSave: false,
        };
      } else if (cashReceiptsKey === "card") {
        return {
          cashReceiptType: "PERSONAL",
          cashReceiptIdType: "CARD_NO",
          cashReceiptId: cashReceiptsNumber,
          isCashReceiptSave: false,
        };
      } else if (cashReceiptsKey === "business") {
        return {
          cashReceiptType: "COMPANY",
          cashReceiptIdType: "COMPANY_NO",
          cashReceiptId: cashReceiptsNumber,
          isCashReceiptSave: false,
        };
      }

      return undefined;
    };

    const isAgreed = ref(false);

    const requestCreateOrder = async (
      shopIdx: number,
      shopperInfo: ShopperInfo,
      productList: SelectedProduct[],
      shippingInfo: ShippingInfo,
      config: {
        personalCustomsCode?: string;
      } = {}
    ) => {
      const { personalCustomsCode } = config;

      const productListForm = toProductListForm(productList);
      const body = {
        shopIdx,
        productList: productListForm,
        shopperInfo: {
          email: shopperInfo.email,
          name: shopperInfo.name,
          phone: shopperInfo.phone,
          isMktAgree: shopperInfo.isMarketingAgree,
        },
        shippingInfo: {
          address: shippingInfo.address,
          addressDetail: shippingInfo.addressDetail,
          message: shippingInfo.message,
          phone: shippingInfo.phone,
          recipientName: shippingInfo.recipientName,
          zipcode: shippingInfo.zipcode,
        },
        personalCustomsId: personalCustomsCode,
      };

      const res = await axios.post(
        `${process.env.VUE_APP_BACKEND_SERVER}/order/shp/`,
        snakeCaseKeys(body, { deep: true })
      );

      return camelCaseKeys(res.data.data, { deep: true });
    };

    const requestPayment = async (
      orderNo: string,
      payType: string,
      amount: number,
      cashReceipts?: {
        cashReceiptType: "PERSONAL" | "COMPANY";
        cashReceiptIdType: "PHONE" | "SSN" | "COMPANY_NO" | "CARD_NO";
        cashReceiptId: string;
        isCashReceiptSave: boolean;
      },
      depositor?: string
    ) => {
      const formattedCashReceipts = cashReceipts
        ? snakeCaseKeys(cashReceipts, { deep: true })
        : {};

      const depositorObj = depositor ? { paying_person_name: depositor } : {};

      const res = await axios.post(
        `${process.env.VUE_APP_BACKEND_SERVER}/order/pay/shp/`,
        {
          order_no: orderNo,
          pay_list: [
            {
              pay_type: payType,
              amount: amount,
              ...formattedCashReceipts,
              ...depositorObj,
            },
          ],
        }
      );

      return camelCaseKeys(res.data.data, { deep: true });
    };

    const orderNo = ref("");
    const nicepayForm = ref();

    const nicepayPayment = async () => {
      if (!isSignDataValid.value) {
        await mutateSignData();

        if (!isSignDataValid.value) {
          alert("결제 모듈에서 문제가 생겼습니다. 다시 시도해주세요.");
          return;
        }
      }

      if (checkPlatform() === "mobile") {
        nicepayForm.value.$el.submit();
      } else {
        goPay(nicepayForm.value.$el);
      }
    };

    const naverpayPayment = async () => {
      const naverpaySDK = getNaverpaySDK();
      const res = await axios.get(
        `${process.env.VUE_APP_BACKEND_SERVER}/naverpay/pre-process/?order_no=${orderNo.value}`
      );
      const naverpayData = res.data.data;
      const totalAmount = amount.value + deliveryFee.value;
      naverpayData.returnUrl = `${process.env.VUE_APP_BACKEND_SERVER}/naverpay/post-process/?usePoints=0&amount=${totalAmount}&shopUrl=${props.shopInfo.urlPath}&orderNo=${orderNo.value}`;
      naverpaySDK.open(naverpayData);
    };

    const isNicepayStart = ref(false);

    const shopperNameEl = ref();
    const shopperEmailEl = ref();
    const shopperPhoneEl = ref();
    const shopperPhoneCheckEl = ref();
    const recipientNameEl = ref();
    const addressEl = ref();
    const recipientPhoneEl = ref();
    const recipientPhoneCheckEl = ref();
    const cashReceiptsInputElList = ref();
    const personalCustomsCodeEl = ref();

    const validateForms = () => {
      validateAllShopperInfo();
      validateAllShippingInfo();

      if (shopperErrors.value.name) {
        shopperNameEl.value.focus();
        return false;
      }

      if (shopperErrors.value.email) {
        shopperEmailEl.value.focus();
        return false;
      }

      if (shopperErrors.value.phone) {
        shopperPhoneEl.value.focus();
        return false;
      }

      if (shopperErrors.value.phoneCheck) {
        shopperPhoneCheckEl.value.focus();
        return false;
      }

      if (
        hasAbroadProduct.value &&
        !checkValidPersonalCustomsCode(personalCustomsCode.value)
      ) {
        isPersonalCustomsCodeError.value = true;
        personalCustomsCodeEl.value.$refs.input.focus();
        return;
      }

      if (shippingErrors.value.recipientName) {
        recipientNameEl.value.focus();
        return false;
      }

      if (shippingErrors.value.address) {
        addressEl.value.$refs.input.focus();
        return false;
      }

      if (shippingErrors.value.phone) {
        recipientPhoneEl.value.focus();
        return false;
      }

      if (shippingErrors.value.phoneCheck) {
        recipientPhoneCheckEl.value.focus();
        return false;
      }

      return true;
    };

    const removeFromCart = (shopIdx: number, removeIndices: number[]) => {
      const cartData = getLocalStorageCart();
      const currentShopCart = cartData[shopIdx];
      const restCart = currentShopCart.filter(
        (cart: any, idx: number) => !removeIndices.includes(idx)
      );
      cartData[shopIdx] = restCart;

      store.commit("setCartItems", cartData);
      localStorage.setItem("cart", JSON.stringify(cartData));
      store.commit("deleteSelectedCartIndices", { shopIdx });
    };

    const payLoading = ref(false);

    const isSellTypeMixed = computed(() => {
      let hasRecruitment = false;
      let hasAlways = false;

      productList.value.forEach((product) => {
        hasRecruitment = hasRecruitment || product.sellType === "RECRUITMENT";
        hasAlways = hasAlways || product.sellType === "ALWAYS";
      });

      return hasRecruitment && hasAlways;
    });
    const isSellTypeMixedErrorModalOpened = ref(false);

    const clickPayBtnHandler = async () => {
      if (payLoading.value) {
        return;
      }

      if (!isAgreed.value) {
        alert("결제 약관에 동의가 필요합니다.");
        return;
      }

      const isAllValid = validateForms();

      if (!isAllValid) {
        return;
      }

      if (!paymentMethod.value) {
        alert("결제 수단을 선택해주세요.");
        return;
      } else if (
        paymentMethod.value === "CARD" ||
        paymentMethod.value === "CELLPHONE"
      ) {
        if (!props.shopInfo) return;
        isNicepayStart.value = true;
        try {
          payLoading.value = true;
          const response = await requestCreateOrder(
            props.shopInfo.idx,
            shopperInfo,
            productList.value,
            shippingInfo,
            {
              ...(hasAbroadProduct.value && {
                personalCustomsCode: personalCustomsCode.value,
              }),
            }
          );

          if (response.amount !== amount.value + deliveryFee.value) {
            alert("상품 정보가 변경되었습니다. 상품을 다시 선택해주세요.");
            router.push({ name: "Myshop" });
            return;
          }

          orderNo.value = response.orderNo;
          nextTick(() => {
            nicepayPayment();
          });
        } catch {
          alert("상품 정보가 변경되었습니다. 상품을 다시 선택해주세요.");
          router.push({ name: "Myshop" });
        } finally {
          const nicepayLayer = document.getElementById("nice_layer");
          if (nicepayLayer) {
            nicepayLayer.remove();
          }
          const bgLayer = document.getElementById("bg_layer");
          if (bgLayer) {
            bgLayer.remove();
          }
          payLoading.value = false;
        }
        return;
      } else if (paymentMethod.value === "NAVER_PAY") {
        if (!props.shopInfo) return;
        try {
          const orderInfo = await requestCreateOrder(
            props.shopInfo.idx,
            shopperInfo,
            productList.value,
            shippingInfo
          );
          orderNo.value = orderInfo.orderNo;
          await naverpayPayment();
        } catch {
          alert("주문 요청에 실패했습니다.");
        }
        return;
      } else if (paymentMethod.value === "BANK") {
        if (isSellTypeMixed.value) {
          isSellTypeMixedErrorModalOpened.value = true;
          return;
        }

        if (!props.shopInfo) return;

        if (depositorName.value.length === 0) {
          alert("입금자명을 입력해주세요");
          depositorNameInputEl.value.$refs.input.focus();
          return;
        }

        if (
          selectedCashReceiptsKey.value !== "none" &&
          !checkCashReceiptsNumber(
            selectedCashReceiptsKey.value,
            cashReceiptsNumber.value
          )
        ) {
          alert("현금영수증 신청 번호를 정확히 입력해주세요.");
          cashReceiptsInputElList.value[0].$el.focus();
          return;
        }

        const cashReceiptsForm = toCashReceiptsForm(
          selectedCashReceiptsKey.value,
          cashReceiptsNumber.value
        );

        try {
          payLoading.value = true;
          const response = await requestCreateOrder(
            props.shopInfo.idx,
            shopperInfo,
            productList.value,
            shippingInfo,
            {
              ...(hasAbroadProduct.value && {
                personalCustomsCode: personalCustomsCode.value,
              }),
            }
          );

          if (response.amount !== amount.value + deliveryFee.value) {
            alert("상품 정보가 변경되었습니다. 상품을 다시 선택해주세요.");
            router.push({ name: "Myshop" });
            return;
          }
          orderNo.value = response.orderNo;

          const res = await requestPayment(
            orderNo.value,
            "ACCOUNT",
            amount.value + deliveryFee.value,
            cashReceiptsForm,
            depositorName.value
          );

          store.commit("deleteOrderProducts", { shopIdx: props.shopInfo.idx });

          if (selectedCartIndices.value.length > 0) {
            removeFromCart(props.shopInfo.idx, selectedCartIndices.value);
          }

          router.push({
            name: "OrderResult",
            params: {
              shopUrl: props.shopInfo.urlPath,
              orderNo: orderNo.value,
              orderResult: JSON.stringify({
                ...res,
                hasRecruitmentProduct: hasRecruitmentProduct.value,
              }),
            },
          });
        } catch (error) {
          alert("상품 정보가 변경되었습니다. 상품을 다시 선택해주세요.");
          router.push({ name: "Myshop" });
        } finally {
          payLoading.value = false;
        }
        return;
      }
    };

    const nicepaySubmit = async () => {
      try {
        const res = await axios.post(
          `${process.env.VUE_APP_BACKEND_SERVER}/order/pay/shp/`,
          {
            order_no: nicepayForm.value.$el.Moid.value,
            pay_list: [
              {
                pay_type: "NICEPAY_AUTH",
                amount: nicepayForm.value.$el.Amt.value,
                auth_result_code: nicepayForm.value.$el.AuthResultCode.value,
                TID: nicepayForm.value.$el.TxTid.value,
                auth_token: nicepayForm.value.$el.AuthToken.value,
                next_app_URL: nicepayForm.value.$el.NextAppURL.value,
                auth_signature: nicepayForm.value.$el.Signature.value,
              },
            ],
          }
        );

        const orderResult = camelCaseKeys(res.data.data, { deep: true });

        store.commit("deleteOrderProducts", { shopIdx: props.shopInfo.idx });

        if (props.shopInfo && selectedCartIndices.value.length > 0) {
          removeFromCart(props.shopInfo.idx, selectedCartIndices.value);
        }

        router.push({
          name: "OrderResult",
          params: {
            shopUrl: props.shopInfo.urlPath,
            orderNo: orderNo.value,
            orderResult: JSON.stringify(orderResult),
          },
        });
      } catch {
        alert("결제에 실패했습니다.");
      } finally {
        const nicepayLayer = document.getElementById("nice_layer");
        if (nicepayLayer) {
          nicepayLayer.remove();
        }
        const bgLayer = document.getElementById("bg_layer");
        if (bgLayer) {
          bgLayer.remove();
        }
      }
    };

    const goBackHandler = () => {
      router.go(-1);
    };

    watchEffect(() => {
      if (
        isDeliveryRequestModalOpened.value ||
        payLoading.value ||
        isSellTypeMixedErrorModalOpened.value
      ) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    });

    onBeforeUnmount(() => {
      document.body.classList.remove("modal-open");
    });

    const agreementCheckLabelMap = {
      abroad:
        "위 주문 내용을 확인 하였으며, 본인은 개인통관고유부호 수집 및 판매자 제공동의, 개인정보 제공동의 및 결제에 동의합니다.",
      default:
        "위 주문 내용을 확인 하였으며, 본인은 개인정보 이용 및 개인정보 제공 동의 확인 하여 결제에 동의합니다.",
    };

    const agreementListMap = {
      abroad: "개인통관고유부호 수집 및 판매자 제공 동의",
      personal: "개인정보 제공 동의",
    };

    type AgreementType = keyof typeof agreementListMap;

    const currentAgreementType = ref<null | AgreementType>(null);

    const agreementCheckLabel = computed(() => {
      const key = hasAbroadProduct.value ? "abroad" : "default";

      return agreementCheckLabelMap[key] ?? agreementCheckLabelMap.default;
    });

    const openAgreementDetail = (agreementType: AgreementType) => {
      if (agreementType === "personal") {
        window.open("https://about.tagby.io/privacy");
        return;
      }
      router.push({
        name: "OrderTerms",
        params: {
          termType: agreementType,
        },
      });
    };

    return {
      productList,
      selectedCartIndices,
      orderPreviews,
      shopperInfo,
      shopperErrors,
      shippingInfo,
      shippingErrors,
      isPersonalCustomsCodeError,
      isDeliveryRequestModalOpened,
      deliveryRequestInputText,
      deliveryRequestList,
      isDeliveryRequestMemoVisible,
      isProductListOpened,
      productListButtonText,
      paymentMethod,
      isAgreed,
      deliveryFee,
      amount,
      orderNo,
      nicepayForm,
      isNicepayStart,
      shopperNameEl,
      shopperEmailEl,
      shopperPhoneEl,
      shopperPhoneCheckEl,
      recipientNameEl,
      addressEl,
      recipientPhoneEl,
      addressSearchContainerEl,
      isAddressSearchContainerVisible,
      point,
      bankInfo,
      cashReceiptsList,
      isCashReceiptsModalOpened,
      selectCashReceipstHandler,
      selectedCashReceipts,
      isPersonalCashReceiptsModalOpened,
      personalCashReceiptsList,
      selectedPersonalCashReceipts,
      selectPersonalCashReceiptsHandler,
      selectedCashReceiptsKey,
      cashReceiptsNumberInputData,
      cashReceiptsNumber,
      cashReceiptsInputElList,
      depositorName,
      depositorNameInputEl,
      payLoading,
      agreementCheckLabel,
      hasAbroadProduct,
      personalCustomsCodeEl,
      agreementListMap,
      currentAgreementType,
      personalCustomsCode,

      validateShopperInfo,
      inputShopperHandler,
      validateShippingInfo,
      inputShippingHandler,
      openAddressSearchHandler,
      clickDefaultDeliveryRequest,
      clickDeliveryRequestMemoHandler,
      clickPayBtnHandler,
      formatNumber,
      filterOnlyNumber,
      nicepaySubmit,
      goBackHandler,
      inputPersonalCustomsCodeHandler,
      blurPersonalCustomsCodeHandler,
      openAgreementDetail,
      ediDate,
      signData,
      totalLocalProductsPrice,
      isShippingInfoEqual,
      recipientPhoneCheckEl,
      hasRecruitmentProduct,
      isSellTypeMixedErrorModalOpened,
    };
  },
});

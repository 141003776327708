import { computed, reactive, ref } from "@vue/composition-api";

type UseFormProps<Values> = {
  initialValues: Values;
  validators?: {
    [name in keyof Values]?: (value: Values[name]) => string | null;
  };
};

export const useForm = <Values extends { [name: string]: any }>({
  initialValues,
  validators,
}: UseFormProps<Values>) => {
  const values = reactive(initialValues);
  const errors = ref<{ [name in keyof Values]?: string }>({});
  const isValid = computed(() => Object.keys(errors.value).length === 0);

  const dissoc = <T>(obj: T, key: keyof T) => {
    const { [key]: _, ...newObj } = obj;
    return newObj;
  };

  const input = <Name extends keyof typeof values>(
    name: Name,
    value: typeof values[Name]
  ) => {
    values[name] = value;
    errors.value = dissoc(errors.value, name);
  };

  const validate = (name: keyof Values) => {
    if (!validators || !validators[name]) return;
    const validator = validators[name];
    if (typeof validator === "function") {
      const error = validator(values[name as string]);
      if (error) {
        errors.value = { ...errors.value, [name]: error };
      } else {
        errors.value = dissoc(errors.value, name);
      }
    }
  };

  const validateAll = () => {
    if (!validators) return;
    Object.entries(validators).forEach(([name, validator]) => {
      const value = values[name];
      if (typeof validator === "function") {
        const error = validator(value);
        if (error) {
          errors.value = { ...errors.value, [name]: error };
        }
      }
    });
  };

  return { values, errors, isValid, input, validate, validateAll };
};

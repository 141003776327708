
















import { SelectedProduct, Option } from "@/components/Product/Types";

import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    product: {
      type: Object as () => SelectedProduct,
      required: true,
    },
  },
  setup() {
    const getOptionNames = (options: Option[]) => {
      const optionsNames = options.map((option) => option.name).join(", ");

      return optionsNames;
    };

    const getTotalPrice = (product: SelectedProduct) => {
      return 0;
      // if (product.options[0].optionIdx === null) {
      //   return product.price * product.options[0].qty;
      // }

      // const pricePerOptions = product.options.map((option) => {
      //   const price = option.path.reduce(
      //     (acc, cur) => acc + cur.addPrice,
      //     product.price
      //   );

      //   return price * option.qty;
      // });

      // const totalPrice = pricePerOptions.reduce((acc, cur) => acc + cur, 0);

      // return totalPrice;
    };

    return {
      getOptionNames,
      getTotalPrice,
    };
  },
});

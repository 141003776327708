
































import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    payMethod: {
      type: String,
      required: true,
    },
    shopUrl: {
      type: String,
      default: "tagby",
    },
    orderNo: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    productName: {
      type: String,
      required: true,
    },
    buyerName: {
      type: String,
      required: true,
    },
    buyerEmail: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
    point: {
      type: Number,
      required: true,
    },
    signData: {
      type: String,
      required: true,
    },
    ediDate: {
      type: String,
      required: true,
    },
  },
  emits: ["submit"],
  setup(props) {
    const mid = `${process.env.VUE_APP_NICEPAY_AUTH_MID}`;
    const returnUrl = `${process.env.VUE_APP_BACKEND_SERVER}/nicepay/post-process/mobile/refactory/?use_point=${props.point}`;

    const nicepayPaymentMethod = computed(() => {
      return props.payMethod === "NAVER_PAY" ? "CARD" : props.payMethod;
    });

    const directShowOpt = computed(() => {
      return props.payMethod === "NAVER_PAY" ? "CARD" : "";
    });

    const directEasyPay = computed(() => {
      return props.payMethod === "NAVER_PAY" ? "E020" : "";
    });

    const easyPayMethod = computed(() => {
      return props.payMethod === "NAVER_PAY" ? "E020=CARD" : "";
    });

    return {
      mid,
      returnUrl,
      nicepayPaymentMethod,
      directShowOpt,
      directEasyPay,
      easyPayMethod,
    };
  },
});

import axios from "axios";
import camelcaseKeys from "camelcase-keys";

const requestBankInfo = async () => {
  const res = await axios.get(
    `${process.env.VUE_APP_BACKEND_SERVER}/info/bank/`
  );
  return camelcaseKeys(res.data, { deep: true });
};

export default requestBankInfo;

import { computed, ref, unref, watch } from "@vue/composition-api";
import moment from "moment";

import type { Ref } from "@vue/composition-api";

import { generateSignData } from "@/api/nicepay";

export const useNicepaySignData = (amount: Ref<number>) => {
  const ediDate = ref(moment().format("YYYYMMDDHHmmss"));
  const isError = ref(false);
  const signData = ref("");

  const resetEdiDate = () => {
    ediDate.value = moment().format("YYYYMMDDHHmmss");
  };

  const isValid = computed(() => {
    return !isError.value && signData.value.length > 0;
  });

  const mutate = async () => {
    try {
      resetEdiDate();
      const data = await generateSignData({
        amount: unref(amount),
        ediDate: ediDate.value,
      });

      signData.value = data;
      isError.value = false;
    } catch {
      isError.value = true;
    }
  };

  watch(
    () => unref(amount),
    async () => {
      await mutate();
    }
  );

  return {
    ediDate,
    isError,
    signData,
    isValid,
    mutate,
  };
};

export default useNicepaySignData;

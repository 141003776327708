import axios from "axios";
import camelcaseKeys from "camelcase-keys";

const api = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_SERVER,
});

export interface GenderateSignDataBody {
  amount: number;
  ediDate: string;
}

export const generateSignData = async (body: GenderateSignDataBody) => {
  const { amount, ediDate } = body;

  const res = await api.post("/nicepay/signature/", {
    amount: amount.toString(),
    edi_date: ediDate,
  });

  const data = camelcaseKeys(res.data.data, { deep: true });

  const { signData } = data;

  return signData;
};








import { defineComponent } from "@vue/composition-api";

import BangMark from "@/assets/ElementsImage/BangMark.vue";

export default defineComponent({
  components: { BangMark },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
});

<template>
  <ul class="order-agreement-list">
    <slot></slot>
  </ul>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({});
</script>

<style></style>
